.item-page-footer {
	&__container {
		display: flex;
		flex-direction: row;
		margin: 10px 0px;
		color: #888;
		font-size: 18px;
		line-height: 1.3;

		b {
			margin-right: 10px;
			display: inline-block;
			float: left;
		}
		@media screen and (max-width: 800px) {
			justify-content: center;
		}
	}

	&__btn-container {
		width: 100%;
		margin: 25px 0px;
		display: flex;
		align-items: center;

		@media screen and (max-width: 800px) {
			display: flex;
			justify-content: space-around;
		}
	}
	&__btn-play {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		background-color: white;
		padding: 15px 25px;
		color: black;
		margin-right: 20px;
		text-decoration: none;
		font-weight: bold;
		font-size: 1rem;
		&:hover {
			opacity: 0.7;
			cursor: pointer;
		}
	}
	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		background-color: #757575;
		padding: 15px 25px;
		color: white;
		font-weight: bold;
		font-size: 1rem;
		// border: none;
		// border-radius: 2px;

		// padding: 9px 11px;
		// background-color: rgba(51, 51, 51, 0.4);
		// border: none;
		// font-weight: 400;
		// margin-right: 30px;
		// text-decoration: none;
		// text-transform: capitalize;
		// transition: all 0.3s;
		// color: white;
		// width: 150px;
		cursor: pointer;

		&:hover {
			opacity: 0.7;
			cursor: pointer;
		}

		@media screen and (max-width: 600px) {
			margin: unset;
			background-color: rgba(51, 51, 51, 0.7);
		}
	}

	&__icon {
		margin-right: 4px;
	}
}
