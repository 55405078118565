.item-page {
	@media screen and (max-width: 800px) {
		overflow-x: hidden;
	}

	&__bg {
		background: #000;
		min-height: 100%;
		min-width: 1024px;
		filter: blur(1.3px) brightness(60%);
		width: 100%;
		height: auto;
		// position: fixed;
		top: 0;
		left: 0;

		@media screen and (max-width: 800px) {
			left: 50%;
			margin-left: -512px;
		}
	}

	.item {
		width: 100%;
		display: flex;
		margin: 10px;

		&__outer {
			background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
			margin-top: 85px;
			height: 100%;
			width: 45%;
			position: absolute;
			top: -85px;
			background: linear-gradient(90deg, #000 85%, transparent 95%) !important;
			z-index: 10;
			display: flex;
			align-items: center;

			@media screen and (max-width: 800px) {
				margin-top: 150px;
				margin-bottom: 20px;
				width: 100%;
				height: 1000px;
				background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
			}
		}

		&__inner {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			@media screen and (max-width: 800px) {
				flex-direction: column;
			}
		}

		&__img-box {
			@media screen and (max-width: 800px) {
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}

		&__poster-img {
			width: 100px;
			height: auto;

			filter: brightness(105%);
			filter: contrast(110%);

			@media screen and (max-width: 800px) {
				width: 265px;
				height: 430px;
				margin: 10px auto;
			}
		}

		&__text-box {
			width: 100%;
			color: white;
			font-size: 15px;
			margin-left: 15px;
			margin-right: 22px;

			font-weight: lighter;
			padding: 5px;
			font-family: sans-serif;
			text-shadow: 3px 3px 4px black;

			@media screen and (max-width: 800px) {
				margin: unset;
				margin: 0 auto;
				width: 90%;
				text-align: center;
				padding-bottom: 15px;
				font-weight: bolder;
			}
		}

		&__title {
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 23px;
		}

		&__overview {
			color: #888;
			font-size: 18px;
			margin-bottom: 5px;
			line-height: 1.3;
		}
		.rating {
			font-size: 20px;
			font-weight: bold;
			display: flex;
			align-items: center;
			b {
				font-size: 30px;
				font-weight: normal;
				margin: 5px 10px;
			}
		}

		.item-rating {
			padding: 5px;
			background-color: black;
			display: inline-flex;
			align-items: center;
			margin-top: 15px;
			border-radius: 5px;

			@media screen and (max-width: 800px) {
				margin-top: 15px;
			}

			&__rank {
				font-size: 1.1rem;
				font-weight: lighter;
				margin-left: 7px;
				margin-top: 3px;
			}

			&__ten {
				font-size: 14px;
				margin-top: 4px;
			}

			&__imdb {
				width: 50px;
			}

			&__star {
				width: 30px;
			}
		}

		&__cast-title {
			font-size: 1.2rem;
			text-decoration: underline;

			@media screen and (max-width: 800px) {
				padding-top: 10px;
			}
		}
	}
}
.youtube-area {
	height: 100vh;
	width: 65%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	position: relative;
	top: -70px;
	left: 35%;
	cursor: none;
}
.social-icons {
	padding: 0 20px;
	button {
		margin: 5px;
		.twitter {
			border-radius: 5px;
			border: 2px solid #38abec;
		}
		.facebook {
			border-radius: 5px;
			border: 2px solid #3a5897;
		}
		.whatsapp {
			border-radius: 5px;
			border: 2px solid #45d265;
		}
		.twitter:hover {
			background-color: #38abec;
		}
		.facebook:hover {
			background-color: #3a5897;
		}
		.whatsapp:hover {
			background-color: #45d265;
		}
	}
}
