.search-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;

  &:hover {
    .search-item__rating,
    .search-item__addtolist {
      visibility: visible;
    }
    .search-item__img {
      opacity: 0.5;
    }
  }

  .search-item__rating,
  .addtolist {
    &:hover {
      .search-item__img {
          opacity: 0.5;
        }
    }
  }

&__img-box {
  display: flex;
  width: 100%;
  justify-content: center;
}

&__img {
  width: 240px;
  height: 360px;
  cursor: pointer;
  transition: opacity 0.4s, ease 0.6s;

  @media screen and (max-width: 800px) {
    width: 200px;
    height: 320px;
  }
}

&__title {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

&__rating-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

&__rating {
  padding: 5px;
  background-color: black;
  display: inline-flex;
  align-items: center;
  margin-top: 15px;
  border-radius: 5px;
  position: absolute;
  top: 0%;
  visibility: hidden;
  z-index: 40;
  transition: all 0.1s;
}

&__rank {
  font-size: 1.1rem;
  font-weight: lighter;
  margin-left: 7px;
  margin-top: 3px;
  color: white;
}

&__ten {
  font-size: 14px;
  margin-top: 4px;
  color: white;
}

&__imdb {
  width: 50px;
}

&__star {
  width: 30px;
}

&__addtolist {
  position: absolute;
  color: white;
  bottom: 18%;
  text-shadow: 5px 5px 6px black;
  z-index: 40;
  cursor: pointer;
  visibility: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
}
