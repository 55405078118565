.addtolist {
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    transition: all 0.1s ease;
    text-shadow: 3px 3px 4px black;
  }

  &--icon {
    padding: 5px;
    border-radius: 50%;
    border: 1px solid white;
    display: inline;
    margin-right: 5px;

    &:hover {
      transition: all 0.2s ease-in;
      transform: scale(1.1);
    }
  }
}
